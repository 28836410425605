import Cookies from 'js-cookie';
module.exports = {
    domain: 'http://dev.api.huligou.com',
    api: 'https://api.huligou.com',
    apiMock: 'http://yapi.c5game.cn/mock/55',
    uploader: 'https://huligou.oss-cn-shanghai.aliyuncs.com',	//图片上传生产环境地址
    c5_login: 'https://www.c5game.com/sso/loginNew.html?appId=10002&redirect_url=https%3A%2F%2Fwww.huligou.com/',	//c5登录生产环境地址
    getToken: function() {
        return Cookies.get('access_token')
    }
} 