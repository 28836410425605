/*
 * 验证码发送倒计时
 *
 * @param {Object} data
 *        {jQuery} el 操作对象
 *        {Number} count 倒计时时间
 *        {String} class 不可点击类名
 *        {String} accessText 倒计时文案
 *        {String} resetText 倒计时结束文案
 * @return {void}
 */ 
export default function(data){
    const DEFAULT_CONFIG = {
        el: null,
        count: 60,
        class: 'noClick',
        accessText: '秒后重发',
        resetText: '重新发送'  
    }

    const oConfig = $.extend({}, DEFAULT_CONFIG, data);
    const $el = oConfig.el;

    //执行函数
    let nInterval = null,
        nCount = oConfig.count,

        ACCESS_CODE_STYLE = nCount + oConfig.accessText
    ;

    $el.html(ACCESS_CODE_STYLE).addClass(oConfig.class);    
    nInterval = setInterval(function(){
        ACCESS_CODE_STYLE = (nCount - 1) + oConfig.accessText
        
        $el.html(ACCESS_CODE_STYLE).addClass(oConfig.class);
        
        if(nCount == 1){ 
            $el.html(oConfig.resetText).removeClass(oConfig.class);  
            clearInterval(nInterval);  
        }else{                  
            nCount --;
        }
    }, 1000);
}