import bootbox from '../../bootbox.js';

/**
 * Say 消失浮层
 * 
 * @param {Object} data
 *        type {String} 图标类型
 *        className {String} 弹窗类名
 *        message {String} 内容 
 *        showTime {Number} 显示时间
 * @param {Function} callback 消失后的回调
 * @return {say}
 */
export default function(data, cb){
    const DEFAULT_CONFIG = {
        title: '&',
        type: 'success',
    	className: 'gx-modal-say',
    	message: '',
        showTime: 2,
        callback: null
    };

    const oConfig = $.extend(true, DEFAULT_CONFIG, data);
    const say = bootbox.alert(oConfig);

    say.init(function(){
        say.find(".modal-header").html('<i class="icon '+ oConfig.type +'"></i>');
        say.find(".modal-footer").remove();
    });

    setTimeout(function(){
        say.modal("hide");
        oConfig.callback && oConfig.callback(); 
    }, oConfig.showTime * 1000);

    return say;
}