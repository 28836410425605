import slick from '../../slick.js';

/**
 * Slider 旋转木马
 * 
 * @param {Object} data
 *        el {jQuery} 对象
 *        dots {Boolen} 指示点
 *        arrows {Boolen} 左右箭头
 *        fade {Boolen} 淡入淡出
 *        speed {Number} 滑动时间
 *        infinite {Boolen} 循环播放
 *        autoplay {Boolen} 自动播放
 *        autoplaySpeed {Number} 自动播放间隔
 *        slidesToShow {Number} 幻灯片每屏显示个数
 *        slidesToScroll {Number} 幻灯片每次滑动个数
 * @return {slider}
 */
export default function(data){
	const DEFAULT_CONFIG = {
		el: '',
		dots: false,
		arrows: true,
		fade: false,
		speed: 500,
		infinite: false,
    	autoplay: false,
    	autoplaySpeed: 3000,
    	slidesToShow: 5,
    	slidesToScroll: 1,
    	onInit: function(){
		  	$(".slick-prev").html(
		  		'<i class="iconfont icon-leftArrow"></i>'
			);
			
		  	$(".slick-next").html(
		  		'<i class="iconfont icon-rightArrow"></i>'
			);
	  	}
    };

    const oConfig = $.extend(true, DEFAULT_CONFIG, data);
    const slider = oConfig.el.slick(oConfig);

    return slider;
}