import config from 'config';
import Cookies from 'js-cookie';
import say from '../../extends/modal/say.js';

export default function (url, data, api = config.api) {
    if (data == 1 || api === 1) {
       api = config.apiMock
    }
    const defaultData = {
        needLogin: false,
        contentType: 'application/json',
        url: api + url,
        type: 'GET',
        dataType: 'json',
        data: '',
        beforeSend: function (xhr) {
            if(defaultData.type.toUpperCase() == "POST"){
                xhr.setRequestHeader("sign", $.md5(defaultData.data));
            }

            if(defaultData.needLogin){
                xhr.setRequestHeader("access-token", config.getToken()); 

                // if(config.getToken() == 'undefined'){
                //     ajax('/api-member/v1/user-token/refresh', {
                //         type: 'POST'
                //     }).then(function(res){
                //         const nCode = res.code;
                //         const sMsg = res.msg;

                //         if(nCode == 0){
                //             Cookies.set('access_token', res.result["access-token"], {
                //                 expires: 2 / 24
                //             });
                //         }
                //     }); 
                // }else{
                //    xhr.setRequestHeader("access-token", config.getToken()); 
                // }
            }
        },
        // 统一的成功处理方法
        success: function(res) {
            const code = parseInt(res.code);

            try{
                switch (code) {
                    case 101:
                        Cookies.remove('access_token');
                        localStorage.removeItem("nickname"); 
                        localStorage.removeItem("shopId"); 

                        window.location.href = "/account/login";
                        break;
                    case 406:
                        say({
                            type: "error",
                            message: "请求正在等待中"
                        });
                        break;
                    default: 
                        break;
                }
            } catch(e) {

            }
        },
        // 统一的异常处理方法
        error: function(response) {
            const status = parseInt(response.status);

            try{
                switch (status) {
                    case 404:
                        window.location.href = "/error/four";
                        break;
                    case 500:
                        window.location.href = "/error/five";
                        break;
                    default: 
                        break;
                }
            } catch(e) {

            }
        }
    }
    const opts = $.extend(true, defaultData, data);
    // if (opts.needLogin) {
    //     opts.data._token = config.getToken()
    // }
    return $.ajax(opts)
}