import '../../../css/views/index/index.scss';

import template from '../../extends/template.js';
import say from '../../extends/modal/say.js';
import alertDom from '../../extends/components/alertDom.js';
import dialog from '../../extends/modal/dialog.js';
import ajax from '../../extends/components/ajax.js';
import slider from '../../extends/components/slider.js';
import getUrlParam from '../../extends/components/getUrlParam.js';
import setCodeTime from '../../extends/components/setCodeTime.js';
import Cookies from 'js-cookie';


/*
 * 数据初始化
 * 
 * @since 2018-5-21
 * @author xieyun
 * 
 */

/* 去除外层container */
$(".index-banner").unwrap();


/* 获取banner */
ajax('/api-system/v1/banner/list', {
	type: 'GET',
	data: {categoryId: 0}
}).then(function(res){
	const sHtml = template('index-banner-tmpl', res);
	$(".index-banner").prepend(sHtml);

	// 首页轮播
	slider({
		el: $('.index-banner .slick'),
		dots: true,
		arrows: false,
		fade: true,
		infinite: true,
		autoplay: true,
		slidesToShow: 1,
		onInit: function(){
			$(".index-banner .slick-dots").wrapInner('<div class="contain"></div>');
		}
	});
});

/* 获取推荐列表 */
ajax('/api-item/v1/item/show-tag', {
	type: 'GET',
}).then(function(res){
	const sRecommendHtml = template('index-recommend-tmpl', res.result[0]),
	 	  sNewHtml = template('index-new-tmpl', res.result[1])
	;

	$(".index-recommend").html(sRecommendHtml);
	$(".index-new").html(sNewHtml);

	// 懒加载 
	$(".j_Lazy").lazyload({
		event: "load"
	});

	// 电竞专用切换
	slider({
		el: $('.index-recommend .slick'),
		dots: true,
		infinite: true,
		slidesToShow: 1
	});

	// 新品首发切换
	slider({
		el: $('.index-new .slick'),
		infinite: true,
		slidesToShow: 4
	});
});

/* 获取分类商品信息 */
ajax('/api-item/v1/item/home-item-category/list', {
	type: 'GET',
}).then(function(res){
	const sHtml = template('index-good-tmpl', res.result);
	$("#bodyer .container").append(sHtml);

	// 懒加载 
	$(".j_Lazy").lazyload({
		event: "load"
	});
});


/*
 * 绑定手机
 *
 * @since 2018-5-23
 * @author xieyun
 */
c5login();
function c5login(){
	let openid = getUrlParam('openid'),
		sign = getUrlParam('sign')
	;
	
	if(openid != null && sign != null){
		const oData = {
			openid: openid,
			sign: sign
		};

		ajax('/api-member/v1/user/c5-login', {
			type: 'POST',
			data: JSON.stringify(oData)
		}).then(function(res){
			const nCode = res.code;
			const sMsg = res.msg;

			if(nCode == 10001){
				//未绑定的用户
				dialogLoginByC5();
			}else if(nCode != 0){
				say({
					type: "error",
					message: sMsg
				});
			}else{
				Cookies.set('access_token', res.result["access-token"], {
			  		expires: 2 / 24
				});
				localStorage.setItem("nickname", res.result.nickname);
				localStorage.setItem("shopId", res.result.shopId);

				window.location.href = "/";
			}       
		});
	}

	/*
	 * 绑定手机弹窗
	 *
	 * @method
	 * @return {void}
	 */
	function dialogLoginByC5(){
		const oFrom = {
	 		forms: [
	 			{
	 				name: 'userName',
	 				type: 'text',
	 				value: '',
	 				placeholder: '请输入手机账号'
	 			},
	 			{
	 				name: 'captcha',
	 				class: 'form-verify',
	 				type: 'text',
	 				value: '',
	 				placeholder: '请输入短信验证码',
	 				btn: '<b class="text-center acquireCode f14">获取验证码</b>'
	 			}
	 		]
	 	};

		dialog({
		 	title: '填写完善你的账号资料',
		 	message: template('bind-phone-tmpl', oFrom),
		 	buttons: {
		 		btn1: {
		 			callback: function (){
		 				return false;
		 			}
		 		}
		 	}
		}, function(dialog){
			const jFrom = dialog.find('form');

			dialog.find(".modal-content").wrapInner('<form method="post"></form>');
	        dialog.find(".btn-ok").attr("type", "submit");
	        dialog.find(".btn-ok").on("click", function(e){
	            e.stopPropagation();
	        });

	        // 发送短信验证码
			dialog.find(".acquireCode").on("click", function(){
				let jThis = $(this),
		            jThisForm = jThis.parents("form")
		        ;

		        if(! jThis.hasClass("noClick") && jThisForm.validate().element(jThisForm.find("input[name=userName]"))){
		            const oValidateData = {
		                phone: jThisForm.find('input[name="userName"]').val()
		            };
		            const oSubmitData = {
		                mobile: jThisForm.find('input[name="userName"]').val(),
		                type: 8
		            };

                    ajax('/api-member/v1/user/sms/send', {
                        type: 'POST',
                        data: JSON.stringify(oSubmitData)
                    }).then(function(res){
                        const nCode = res.code;
                        const sMsg = res.msg;
                        if(nCode != 0){
                        	say({
		    					type: "error",
		    					message: sMsg
		    				});
                        }else{
                            //倒计时
                            setCodeTime({
                                el: jThis
                            });
                        }
                    }, function() {
                        // 异常错误
                    });
		        }else{
		            jThisForm.find("input[name=userName]").focus();
		        }
			});

			// 确认
	        dialog.find('form').validate({
	        	rules: {
	        		'userName': {
		                required: true,
		                formatPhone: true
		            },
		            'captcha': {
		                required: true
		            }
	        	},
	        	messages: {
	        		'userName': {
	                	required: '手机号不能为空'
		            },   
		            'captcha': {
		                required: '验证码不能为空'
		            }
	        	},
	        	showErrors: function(errorMap, errorList){
		            if(errorList.length) {
		                dialog.find(".form-error").html(alertDom({
		                    type: 'warning',
		                    message: errorList[0].message
		                }));
		            }else{
		                dialog.find(".form-error").html("");
		            }
		        },
		        submitHandler: function(form) {
		        	const $form = $(form);
		        	const oData = {
		        		openid: openid,
	        		  	sign: sign,
	                    userName: $form.find("input[name=userName]").val(),
	                    captcha: $form.find("input[name=captcha]").val()
	                };

	                ajax('/api-member/v1/user/c5-register', {
						type: 'POST',
						data: JSON.stringify(oData)
					}).then(function(res){
						const nCode = res.code;
						const sMsg = res.msg;

						if(nCode != 0){
							say({
		    					type: "error",
		    					message: sMsg
		    				});
						}else{
							Cookies.set('access_token', res.result["access-token"], {
						  		expires: 2 / 24
							});
							localStorage.setItem("nickname", res.result.nickname);
							localStorage.setItem("shopId", res.result.shopId);

							window.location.href = "/";
						}
					}, function() {
						// 异常错误
					});
		        }
	        });
		}); 
	}
}




