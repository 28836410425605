import bootbox from '../../bootbox.js';

/**
 * Dialog 自定义弹窗
 * 
 * @param {Object} data
 *        title {String} 标题
 *        className {String} 弹窗类名
 *        message {String} 内容 
 *        buttons {Object} 按钮对象
 * @param {Function} cb
 * @return {dialog}
 */
export default function(data, cb){
    const DEFAULT_CONFIG = {
    	title: '提示',
    	className: 'gx-modal-dialog',
    	message: '',
		buttons: {
			btn1: {
				label: '确认',
				className: 'btn-ok'
			}
		}
    };

    const oConfig = $.extend(true, DEFAULT_CONFIG, data);
    const dialog = bootbox.dialog(oConfig);

    dialog.init(function() {
        cb && cb(dialog);
    });

    return dialog;
}