/*
 * 获取地址栏参数方法
 *
 * @param {String} name 对应需要获得的参数名
 * @return {String}
 */
export default function getUrlParam(name){
	let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)"),
		result = window.location.search.substr(1).match(reg)
	;
	
	if(result != null){
		return decodeURIComponent(decodeURIComponent(result[2]));	
	}else {
		return null;		
	}
} 