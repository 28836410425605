import config from 'config';
export default function (data) {
    const defaultData = {
        icon: true, // 显示小图标
        animate: false, // 出场动画
        type: 'warning',   // 警告类型
        message: '' // 警告内容
    }
    const opts = $.extend({}, defaultData, data);
    const $alert = $('<div class="gx-alert alert alert-' + opts.type + '" role="alert"></div>');
    const $content = $('<div class="media"></div>');
    if (opts.animate) {
        $alert.addClass('twinkle animated');
    }
    if (opts.icon) {
        $content.append('<div class="media-left media-middle"><span class="iconfont icon-'+ opts.type +'"></span></div>');
    }
    $content.append('<div class="media-body media-middle">'+ opts.message +'</div>');
    $alert.append($content);
    return $alert; 
}